import React from "react";
import "./confirmation.css";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";

interface Data {
  companyName: string;
  idNumber: string;
  requestId: string;
  taxNumber: string;
  address: string;
  place: string;
  municipalityId: number;
  municipality: string;
  cantonId: number;
  canton: string;
  entityId: number;
  entity: string;
  responsiblePersonFullName: string;
  responsiblePersonFunction: string;
  companyEmailAddress: string;
  companyPhoneNumber: string;
  websiteUrl: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonEmail: string;
  comments: string;
  attachments: { uid: string }[];
    type: number;
  languageId: string;
}

const titleMapping: { [key: string]: string } = {
  companyName: "Company Name",
  idNumber: "ID Number",
  requestId: 'Request Id',
  taxNumber: "Tax Number",
  areaOfExpertise: "Area of Expertise",
  address: "Address",
  place: "Place",
  municipality: "Municipality",
  canton: "Canton",
  entity: "Entity",
  responsiblePersonFullName: "Responsible Person Full Name",
  responsiblePersonFunction: "Responsible Person Function",
  companyEmailAddress: "Company Email Address",
  companyPhoneNumber: "Company Phone Number",
  websiteUrl: "Website URL",
  contactPersonFirstName: "Contact Person First Name",
  contactPersonLastName: "Contact Person Last Name",
  contactPersonEmail: "Contact Person Email",
  comments: "Comments",
};

const ConfirmationPage = () => {
  const location = useLocation();

  const data = location.state as Data;

  const {
    attachments,
    type,
    languageId,
    municipalityId,
    cantonId,
    entityId,
    ...filteredData
  } = data;

  console.log(data);

  const printDocument = () => {
    // Open the print dialog
    window.print();
  };

  return (
    <div className="confirmation-container">
      <h1 className="title-confirm">Submission Confirmation: Entry Received</h1>

      <div className="success-alert">
        <h1>Success!</h1>
      </div>

      <section id="printable-section">
        <div className="container-data">
          <table>
            {Object.entries(filteredData).map(([key, value]: any) => (
              <tr key={key}>
                <th>{titleMapping[key]}</th>
                <td>{value}</td>
              </tr>
            ))}
          </table>
        </div>
      </section>

      <div className="section-container flex flex-col md:flex-row w-full mt-4">
        <div className="flex justify-end flex-col md:flex-row w-full">
          <Button
            className="btn-next bg-white mb-3 md:mb-0 flex justify-center"
            type="default"
            size="large"
            onClick={printDocument}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
            <span className="ml-4">Print</span>
          </Button>
          <Button
            className="btn-next bg-blue-500 ml-0 md:ml-4"
            type="primary"
            size="large"
          >
            <Link to="/">Go to home page</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
