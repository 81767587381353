import "./CompnayList.css";
import SearchComponent from "../../components/search/searchComponent";
import RegisterListItem from "../../components/register/RegisterListItem";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// const requestApi = new RequestsApi();

export const CompanyList = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { type, id } = useParams();

  console.log(type);

  const handleResponse = ({ data, isLoading }) => {
    setIsLoading(isLoading);
    setSearchResults(data);
  };

  return (
    <>
      <div className="request-overlay overlay-1">
        <p className="title-text">
          {(() => {
            switch (type) {
              case "1":
                return t(
                  "request:service-companies-1",
                  "Owners And Operators Of KGH Equipment"
                );
              case "2":
                return t("request:service-companies-2", "Service Companies");
              case "3":
                return t(
                  "request:service-companies-3",
                  "Importers/Exporters Of KGH Equipment"
                );
              case "4":
                return t("request:service-companies-4", "Certified Technician");
              default:
                return t("request:service-companies", "");
            }
          })()}
        </p>
      </div>
      <div className="home-container">
        <SearchComponent type={type} id={id} onResponse={handleResponse} />
        {loading ? (
          <div className="p-24">
            <span className="loader"></span>
          </div>
        ) : null}
        {searchResults.length !== 0 ? (
          <RegisterListItem searchResults={searchResults} />
        ) : (
          <div className="not-found">Data Not Found</div>
        )}
      </div>
    </>
  );
};
