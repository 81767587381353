import "./home.css";
import { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Divider, Select } from "antd";
import MapChart from "./BosniaMap";
import { PublicApi } from "api/api";
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
const publicApi = new PublicApi();
const { Option } = Select;

interface CompanyCountData {
  entityName: string;
  totalServiceCompanies: number;
  totalKghEquiementCompanies: number;
  totalImportsCompanies: number;
  certifiedTechnicies: number;
}

export const Home = () => {

  const [entityName, setEntityName] = useState("Federacija Bosne i Hercegovine");
  const [entityId, setEntityId] = useState<string>();
  const [countData, setCountData] = useState<CompanyCountData | undefined>();


  const commonData = useContext(CommonDataContext) as any;
  const { stateEntities } = commonData;

  useEffect(() => {


    if (stateEntities.length > 0) {
      onChangeEntity(stateEntities[0]?.id, stateEntities[0]);
      onClickCompanies()

    }
  }, [stateEntities]);




  useEffect(() => {
    console.log('useEffect entiry');
    onClickCompanies();
  }, [entityId])

  const onClickCompanies = async () => {
    if (typeof entityId !== 'string') {
      console.log(entityId,"entityId");
      console.error('Invalid entityId:', entityId);
      return;
    }
    const { data }: any = await publicApi.apiGetCompaniesCount({ entityId });
    console.log(data);

    setCountData(data);
  };


  const navigate = useNavigate();
  const { t } = useTranslation();

  const getEntityName = (name: string) => {
    setEntityName(name)
    if (name === 'Republika Srpska') {

      const republicSrpskaData = stateEntities?.find((state: any) => state.name === "Republika Srpska/Република Српска");

      setEntityId(republicSrpskaData?.id);
      onClickCompanies();

    }
    else if (name === 'Federacija Bosne i Hercegovine') {

      const Federacija = stateEntities?.find((state: any) => state.name === 'Federacija Bosna i Hercegovina/Федерација Босне и Херцеговине');
      setEntityId(Federacija?.id);
      onClickCompanies();

    }
    else if (name === 'Brčko distrikt') {
      const Brčko = stateEntities?.find((state: any) => state.name === 'Brčko distrikt BiH/Брчко дистрикт БиХ');
      setEntityId(Brčko?.id);
      onClickCompanies();

    }
    else{
      setEntityId("");
      setEntityName("Bosnia and Herzegovina")
      onChangeEntity("",null);
    }

  }

  const onChangeEntity = (value: any, record: any) => {
    console.log('entity', typeof value);

    setEntityId(value);
    if (record?.children === 'Republika Srpska/Република Српска') {
      setEntityName('Republika Srpska');
    }
    else if (record?.children === 'Federacija Bosna i Hercegovina/Федерација Босне и Херцеговине') {
      setEntityName('Federacija Bosne i Hercegovine');
    }
    else if (record?.children === 'Brčko distrikt BiH/Брчко дистрикт БиХ') {
      setEntityName('Brčko distrikt');
    }else if(value == ""){
      setEntityName("Bosnia and Herzegovina");
    }
    //onClickCompanies();  

  }

  return (
    <div>
      <div className="dropdown-container">
        <Select
          className="entity-ddl"
          value={entityId}
          dropdownClassName="custom-dropdown"
          suffixIcon={<DownOutlined size={24} color="#000000" />}
          onChange={(value: any, record: any) => onChangeEntity(value, record)}
        >
          <Option className="options" key="1" value="">
            {t("home:drpdown-entity-country-option-1", "Bosnia and Herzegovina")}
          </Option>
            {stateEntities?.map((state: any) => {
            return (
              <Option className="options" key={state?.id} value={state?.id}>
                {state?.name}
              </Option>
            );
          })
          /* <Option className="options" key="1" value="Federacija Bosne i Hercegovine">
            {t("home:drpdown-entity-option-1", "Federation of Bosnia and Herzegovina")}
          </Option>
          <Option className="options" key="2" value="Republika Srpska">
            {t("home:drpdown-entity-option-2", "Republic of Srpska")}
          </Option>
          <Option className="options" key="3" value="Brčko distrikt">
            {t("home:drpdown-entity-option-3", "Brčko District")}
          </Option> */}
        </Select>
      </div>

      <Card bordered={true} className="entity-card">
        <Row>
          <Col lg={13} md={24} sm={24} xs={24}>
            {stateEntities.length > 0 && <MapChart getEntityName={getEntityName} entityName={entityName} />}
          </Col>
          <Col lg={1} md={24} sm={24} xs={24}>
            <Divider type="vertical" className="vertical-divider d-lg-block d-none" />
            <div></div>
          </Col>
          <Col className="home-cards" md={24} sm={24} xs={24} lg={9}>
            <div className="entity-name">{entityName === "Bosnia and Herzegovina" ? `${t("home:drpdown-entity-country-option-1", "Bosnia and Herzegovina")}`:entityName}</div>
            <Divider />
            <Card bordered={false} className="company-card" onClick={() => navigate(`/company-list/2/${entityId}`)}>
              <div className="card-content">
                <div className="card-title">{t("home:service-companies", "Service Companies")}</div>
                <div className="count-box">
                  <span>{countData?.totalServiceCompanies}</span>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="company-card" onClick={() => navigate(`/company-list/1/${entityId}`)}>
              <div className="card-content">
                <div className="card-title">{t("home:owners_Operators:title", "Owners And Operators Of KGH Equipment")}</div>
                <div className="count-box">
                  <span>{countData?.totalKghEquiementCompanies}</span>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="company-card" onClick={() => navigate(`/company-list/3/${entityId}`)}>
              <div className="card-content">
                <div className="card-title">{t("home:importers:title", "Importers")}</div>
                <div className="count-box">
                  <span>{countData?.totalImportsCompanies}</span>
                </div>
              </div>
            </Card>
            <Card bordered={false} className="company-card" onClick={() => navigate(`/company-list/4/${entityId}`)}>
              <div className="card-content">
                <div className="card-title">{t("home:certified-technician", "Certified Technicians")}</div>
                <div className="count-box">
                  <span>{countData?.certifiedTechnicies}</span>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
