import React, { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';
import CustomContextMenu from './context-menu';
import { useTranslation } from 'react-i18next';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [isRequestPage, setIsRequestPage] = useState(false)
  const location = useLocation();
  const {t} = useTranslation();

  console.log(location.pathname);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    setIsRequestPage(location.pathname.includes('requests/create') || location.pathname.includes('register'));
  }, [location]);

  return (
    <header className="sticky-header">
    <Navbar expand="md" className={`navbar-expand-md navbar-toggleable-md box-shadow pt-3 pb-3`} container dark>
      <div className="navbar-header-container">
        <NavbarBrand className="navbar-brand" tag={Link} to="/">
          KGH
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      </div>
      <Collapse className="d-md-inline-flex flex-md-row" isOpen={!collapsed} navbar>
        <div className='nav-div'>
          <Nav className='nav-list'>
            <NavItem className='nav-item'>
              <NavLink href="/" className={`nav-link ${location.pathname === '/' && 'active'}`}>
                {t("navbar:navigation-options:home", "Home")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/report" className={`nav-link ${location.pathname === '/report' && 'active'}`}>
                {t("navbar:navigation-options:report", "Substance Report")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/legislation" className={`nav-link ${location.pathname === '/legislation' && 'active'}`}>
                {t("navbar:navigation-options:legislation", "Legislation")}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <ul className="navbar-nav flex-grow">
          <NavItem style={{ width: '100%' }}>
            <CustomContextMenu isRequestPage={isRequestPage} />
          </NavItem>
        </ul>
      </Collapse>
    </Navbar>
  </header>
  
  );
};
