import React from 'react';
import { FaLinkedin, FaInstagram, FaFacebookF, FaTwitter, FaYoutube, FaSkype, FaSnapchatGhost, FaTiktok, FaWhatsapp, FaGlobe } from 'react-icons/fa';
import { TfiEmail } from 'react-icons/tfi';
import './Footer.css'
import { Divider } from 'antd';
import { PiMediumLogoFill } from "react-icons/pi";
import { FaWhatsappSquare, FaInstagramSquare } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { RiMediumFill } from "react-icons/ri";
import { BsGlobe } from "react-icons/bs";
import { FaSnapchatSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const SocialIcons = ({ icons }) => (
  <div className="flex space-x-2">
    {icons.map((Icon, index) => (
      <Icon key={index} className="text-white text-xl hover:text-gray-300 cursor-pointer" />
    ))}
  </div>
);

const CustomFooter = () => {

  const { t } = useTranslation();
  return (
   <>
  <svg width="0" height="0">
    <linearGradient id="instagram-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" style={{ stopColor: '#fdf497', stopOpacity: 1 }} />
      <stop offset="50%" style={{ stopColor: '#fd5949', stopOpacity: 1 }} />
      <stop offset="75%" style={{ stopColor: '#d6249f', stopOpacity: 1 }} />
      <stop offset="100%" style={{ stopColor: '#285AEB', stopOpacity: 1 }} />
    </linearGradient>
  </svg>
  <footer className="footer">
    <div className="container mx-auto max-w-[1400px] w-full pt-[50px] pb-[50px]">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <h2 className="font-bold mb-4 text-[46px] text-white">KGH</h2>
          <p className="footer-description-text">
            Explore our cutting-edge solar energy solutions designed to power your home or business with clean, renewable energy. Reduce your carbon footprint while saving on electricity costs. Switch to solar today for a brighter, sustainable future!
          </p>
        </div>
        <div className='quick-links-div'>
          <h3 className="quick-links">{t("footer:quick-links", "Quick Links")}</h3>
          <ul className="options-div">
            <li><a href="/" className="options">{t("navbar:navigation-options:home", "Home")} <FaChevronRight className="right-arow" /></a></li>
            <li><a href="/report" className="options">{t("navbar:navigation-options:report", "Substance Report")} <FaChevronRight className="right-arow" /></a></li>
            <li><a href="/legislation" className="options">{t("navbar:navigation-options:legislation", "Legislation")} <FaChevronRight className="right-arow" /></a></li>
          </ul>
        </div>
        <div className='contact-links-div'>
          <h3 className="contact-links">{t("footer:contact-us", "Contact us")}</h3>
          <div className="contact-div">
            {['ministry-of-foreign-trade', 'federation-of-bosnia', 'republic-of-srpska', 'brcko-district'].map((title) => (
              <div className='contact-card' key={title}>
                <p className="mb-2 title">{t(`footer:${title}`, title.replace(/-/g, ' '))}</p>
                <div className="flex justify-between max-w-[194px] w-full">
                  <FaLinkedin color='#117EB8' className="contact-icons" />
                  <FaInstagramSquare className="instagram-icon contact-icons" />
                  <FaFacebookSquare color='#1976D2' className="contact-icons" />
                  <FaSquareXTwitter color='#000000' className="contact-icons" />
                  <FaYoutube color='#FD3832' className="contact-icons" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Divider className="footer-divider" />
        <div className='mail-div'>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4 md:mb-0">
            <a href="mailto:foreign.kgh@gmail.com" className="nav-icon flex items-center">
              <TfiEmail color="#055E9A" className="mail-icon" />foreign.kgh@gmail.com
            </a>
            <a href="mailto:bosnia.kgh@gmail.com" className="nav-icon flex items-center">
              <TfiEmail color="#055E9A" className="mail-icon" />bosnia.kgh@gmail.com
            </a>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
            <a href="mailto:srpska.kgh@gmail.com" className="nav-icon flex items-center">
              <TfiEmail color="#055E9A" className="mail-icon" />srpska.kgh@gmail.com
            </a>
            <a href="mailto:brcko.kgh@gmail.com" className="nav-icon flex items-center">
              <TfiEmail color="#055E9A" className="mail-icon" />brcko.kgh@gmail.com
            </a>
          </div>
        </div>
      <div>
      
      </div>
    </div>
    <div className="copyright-text">
      <p>{t("footer:copyright", "Copyright")} © 2024 KGH. {t("footer:allrights-by", "All Rights Reserved by")} <a className='footer-kgh'>KGH</a></p>
    </div>
  </footer>
</>

  );

};

export default CustomFooter;