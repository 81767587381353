import React from 'react';
import { useTranslation } from 'react-i18next';
import './Legislation.css'

const PrivacyLegislation = () => {
    const {t} = useTranslation()
  const legislations = [
    {
      id: 1,
      title: 'General Data Protection Regulation (GDPR)',
      content: 'For our customers in the European Economic Area (EEA), ESD shall comply with the General Data Protection Regulation (GDPR). Under this legislation, we respect your right to access, modify, and delete your personal data. We also ensure that personal data is processed lawfully, transparently, and in agreement pursuant.'
    },
    {
      id: 2,
      title: 'California Consumer Privacy Act (CCPA)',
      content: 'For residents of California, ESD shall comply with the California Consumer Privacy Act (CCPA). This law provides you with the right to know what personal information is collected, used, shared, or sold. You have the right to request that we delete your data or opt out of its sale.'
    },
    {
      id: 3,
      title: "Children's Online Privacy Protection Act (COPPA)",
      content: 'ESD strictly follows the Childrens Online Privacy Protection Act (COPPA) for our U.S.-based customers. We do not knowingly collect personal information from children under the age of 13 without verified parental consent.'
    },
    {
      id: 4,
      title: 'Personal Information Protection and Electronic Documents Act (PIPEDA)',
      content: 'For our Canadian customers, ESD shall adhere to the Personal Information Protection and Electronic Documents Act (PIPEDA). You can pursue that we obtain your consent before storing or using your personal information and contact you to access and correct your data.'
    },
    {
      id: 5,
      title: 'Australia Privacy Act 1988',
      content: 'Our team complies with the Australia Privacy Act 1988, ensuring that personal information is handled with transparency, fairness, and respect for the individuals privacy.'
    },
    {
      id: 6,
      title: 'Other Regional and National Privacy Laws',
      content: 'ESD strictly ensures that we comply with any other regional or national data protection laws applicable to our operations, including but not limited to:\n• South Korean Data Protection Laws\n• South African Data Protection Law (POPI)\n• India Information Technology Act, 2015, and Data Protection Rules'
    },
    {
      id: 7,
      title: 'International Data Transfers',
      content: 'For any transfer of personal data outside of the country of origin, ESD shall ensure that adequate protection measures are in place, such as standard contractual clauses, to safeguard your information according to the relevant regulations.'
    }
  ];

  return (
    <>
    <div className="details-overlay overlay-1">
        <p className="title-text">
          {t(
            "legislation-page:title",
            "Legislation"
          )}
        </p>
      </div>
    <div className="min-h-screen bg-gradient-to-b  to-white p-8">
      <div className="mx-auto" style={{width:'90%'}}>
      <div className="text-gray-600 leading-relaxed p-6">
       KGH Solar is committed to complying with all applicable laws and regulations regarding data privacy and protection. We ensure that the collection, use, and processing of personal information adhere to the following legislation:
       </div>
        
        <div className="">
          {legislations.map((item) => (
            <div key={item.id} className="p-6 border-b border-gray-200 last:border-b-0">
              <h2 className="text-xl font-semibold  mb-3" style={{color:'#055E9A'}}>
                {item.id}. {item.title}
              </h2>
              <p className="text-gray-600 leading-relaxed">
                {item.content}
              </p>
            </div>
          ))}
          
          <div className="p-6 text-sm text-gray-500 italic">
            For further inquiries regarding our compliance with data protection legislation, feel free to contact us at +88 5142788.
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default PrivacyLegislation;